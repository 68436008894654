
import { defineComponent, ref } from "vue";
import base from "@/api/base.js";
import { ErrorMessage, Field, Form } from "vee-validate";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import * as Yup from "yup";

interface Filter {
  status: string;
  code: string;
}

export default defineComponent({
  name: "stores",

  components: {
    ErrorMessage,
    Field,
    Form,
    VPagination,
  },

  data() {
    const validation = Yup.object().shape({
      cmp: Yup.string().required("필수값입니다."),
      brand: Yup.string().required("필수값입니다."),
      store_nm: Yup.string().required("필수값입니다."),
      store_rep_nm: Yup.string().required("필수값입니다."),
      store_addr: Yup.string().required("필수값입니다."),
      bsns_licnbr: Yup.string().required("필수값입니다."),
      store_ty: Yup.string().required("필수값입니다."),
      store_tel: Yup.string()
        .matches(/^\d{9,11}$/, "-를 제외한 9이상 11자리 이하 숫자를 입력하세요.")
        .required("필수값입니다."),
      store_person_tel: Yup.string()
        .matches(/^\d{11}$/, "-를 제외한 휴대폰 번호 11자리를 입력하세요.")
        .required("필수값입니다."),
      store_email: Yup.string()
        .required("필수값입니다.")
        .email("email 형식이 아닙니다."),
      store_use_yn: Yup.boolean().required("필수값입니다."),
    });

    return {
      stores: [],
      store: {},
      storesData: { cmp: {}, brand: {}, bsns: {} },
      businesses: {},
      companys: {},
      brands: {},
      businessNumber: [{}],
      radioBox: [{}],
      searchcode: "cmp_nm",
      searchdata: "",
      status: "",
      storetype: "",
      page: 1,
      validation,
    };
  },

  created() {
    base
      .getCompanyList()
      .then((res) => {
        console.log(res);
        this.companys = res.data;
      })
      .catch((error) => {
        console.log(error);
      });

    base
      .getBrandList()
      .then((res) => {
        console.log(res);
        this.brands = res.data;
      })
      .catch((error) => {
        console.log(error);
      });

    this.getListData();
  },

  methods: {
    showApi() {
      const daum = (window as any).daum;
      new daum.Postcode({
        oncomplete: (data) => {
          this.store.store_addr = data.roadAddress;
        },
      }).open();
    },

    getSearchResult() {
      this.page = 1;
      this.getListData();
    },

    getListData() {
      const param = {
        [this.searchcode]: this.searchdata,
        bsns_use_yn: this.status,
        page: this.page,
      };
      base
        .getStoreList(param)
        .then((res) => {
          console.log(res);
          this.stores = res.data;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    getData(id) {
      base
        .getStore(id)
        .then((res) => {
          console.log(res);
          this.storesData = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    findData() {
      const businessObject = {
        [this.searchCode]: this.businessSearch,
      };
      base
        .getBusinessNumberList(businessObject)
        .then((res) => {
          console.log(res);
          this.businessNumber = res.data;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    async editData() {
      base
        .updateStore(this.storesData)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            alert("사용자 정보를 수정했습니다.");
            this.modalopen = false;
            window.location.reload();
          } else {
            alert(res.status);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    async submitForm() {
      this.store.bsns = this.radioBox.id;
      console.log(this.store);
      base
        .newStore(this.store)
        .then((res) => {
          console.log(res);
          if (res.status == 201) {
            alert("신규 매장을 등록했습니다.");
            window.location.reload();
          } else {
            alert(res.status);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
      this.clearForm();
    },
  },

  clearForm() {
    for (const key in this.user) {
      this.user[key] = "";
    }
  },

  setup() {
    const data = ref<Filter>({
      status: "1",
      code: "1",
    });

    return {
      data,
    };
  },
});
